import { ContentfulIcon, Event } from '@/types/contentful';
import { useAppSelector } from '../state/hooks';

/**
 * @description it returns the icons from an event variant prioritizing from more important to less important on:
 * - if the user owns a ticket from any of the event variants
 * - the preferred variant
 * - the first event variant available
 * @params {Event} event
 * @returns array of event icons
 * */
const useEventIcons = (event: Event): ContentfulIcon[] => {
  const userTickets: Event[] = useAppSelector(({ tickets }) => tickets.events);

  const ticketsFetchStatus: string = useAppSelector(
    ({ tickets }) => tickets.status,
  );

  const userTicketOfHeroEvent = userTickets.find(
    (ticket) => ticket.slug === event.slug,
  );

  let eventIcons: ContentfulIcon[] = [];

  if (ticketsFetchStatus !== 'IN_PROGRESS') {
    eventIcons =
      userTicketOfHeroEvent?.variants?.[0]?.icons ||
      event?.preferredVariant?.icons ||
      event?.variants?.[0]?.icons ||
      [];
  }
  return eventIcons;
};

export default useEventIcons;
