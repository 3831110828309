import React, { FC } from 'react';
import classNames from 'classnames';
import { ContentfulAccordionBlock } from '@/types/contentful';
import { renderContentfulDescription } from '@/utils/contentful';
import AccordionWrapper from './AccordionWrapper';
const AccordionBlock: FC<{
  block: ContentfulAccordionBlock;
  isFirstBlock: boolean;
}> = ({
  block,
  isFirstBlock
}) => <div className={classNames('container container--vertical-padding', {
  'first-block': isFirstBlock
})} data-sentry-component="AccordionBlock" data-sentry-source-file="index.tsx">
    <AccordionWrapper type={block.type} items={block.accordionItems.map(item => ({
    title: item.title,
    content: renderContentfulDescription(item.content),
    icon: item.icon?.type
  }))} data-sentry-element="AccordionWrapper" data-sentry-source-file="index.tsx" />
  </div>;
export default AccordionBlock;