import { isBefore, isEqual } from 'date-fns';

import { Event, EventVariant } from '../types/contentful';

interface DoorsOpenEvent {
  type: 'event';
  eventOrVariant: Event;
}

interface DoorsOpenEventVariant {
  type: 'eventVariant';
  eventOrVariant: EventVariant;
}

/**
 *
 * @param eventOrVariantPayload - Event or Event's variant along with a sting as identifier (event | eventVariant)
 *  in case of an event it will auto select the event's preferredVariant or first variant as fallback
 * @returns The event's doorsOpen time using the eventStart time as fallback
 */
export function getDoorsOpenTime(
  eventOrVariantPayload: DoorsOpenEvent | DoorsOpenEventVariant,
): string {
  const { type, eventOrVariant } = eventOrVariantPayload;
  let variant: EventVariant | '' = '';

  // check if the Event at least one of the eventStart or doorsOpen populated
  // and pick the preferredVariant or first variant as fallback
  if (
    type === 'event' &&
    (eventOrVariant.variants[0]?.eventStart ||
      eventOrVariant.variants[0]?.doorsOpen)
  ) {
    variant = eventOrVariant.preferredVariant ?? eventOrVariant.variants[0];
  }

  // check if the EventVariant has at least one of the eventStart or doorsOpen populated
  if (
    type === 'eventVariant' &&
    (eventOrVariant.eventStart || eventOrVariant.doorsOpen)
  ) {
    variant = eventOrVariant;
  }

  // in case of an invalid entry return an empty string
  if (!variant) return '';
  // the reason we are checking for the length of the string is because it might be an empty string or null
  // in case of an empty string the value passes the nullish coalescing and the result is not the expected
  // ts complains but we need the check for the above reasons so I will disable the rule
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return variant?.doorsOpen?.length > 0
    ? variant.doorsOpen
    : variant.eventStart;
}

export function sortEvent(a: Event, b: Event) {
  const startA = new Date(
    getDoorsOpenTime({ type: 'event', eventOrVariant: a }),
  );
  const startB = new Date(
    getDoorsOpenTime({ type: 'event', eventOrVariant: b }),
  );
  if (isBefore(startA, startB)) return 1;
  if (!isEqual(startA, startB)) return -1;
  const titleCompare = a.title.localeCompare(b.title);
  if (titleCompare !== 0) return titleCompare;
  return a.id!.localeCompare(b.id!);
}

export function getEventCategories(
  events: Event[],
  currentDate: Date = new Date(),
) {
  const futureEvents: Event[] = [];
  const liveEvents: Event[] = [];
  const pastEvents: Event[] = [];
  const notFoundEvents: Event[] = [];

  for (const event of events) {
    if (event.title) {
      const doorsOpenTime = getDoorsOpenTime({
        type: 'event',
        eventOrVariant: event,
      });

      const doorsOpen = new Date(doorsOpenTime);
      const endDate = new Date(event.variants[0].eventEnd);
      const isFuture = isBefore(currentDate, doorsOpen);

      const isLive =
        isBefore(doorsOpen, currentDate) && isBefore(currentDate, endDate);
      if (isFuture) {
        futureEvents.push(event);
      } else if (isLive || event.variants[0]?.eventStart === null) {
        liveEvents.push(event);
      } else {
        pastEvents.push(event);
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn('Some events were not found, we can not show them...'); // Easier to spot data migration related bugs
      notFoundEvents.push(event);
    }
  }

  futureEvents.sort(sortEvent);
  pastEvents.sort(sortEvent);
  liveEvents.sort(sortEvent);

  return {
    pastEvents,
    liveEvents,
    futureEvents,
    notFoundEvents,
  };
}

function sortVariants(a: EventVariant, b: EventVariant) {
  const startA = new Date(a.eventStart);
  const startB = new Date(b.eventStart);
  if (isBefore(startA, startB)) return 1;
  if (!isEqual(startA, startB)) return -1;
  const titleCompare = a?.title?.localeCompare(b?.title);
  if (titleCompare !== 0) return titleCompare;
  return a.sku.localeCompare(b.sku);
}

export function getEventVariantsCategories(
  variants: EventVariant[],
  currentDate: Date = new Date(),
) {
  const futureVariants: EventVariant[] = [];
  const liveVariants: EventVariant[] = [];
  const pastVariants: EventVariant[] = [];
  const notFoundVariants: EventVariant[] = [];

  for (const variant of variants) {
    if (variant.sku && variant.eventStart) {
      const endDate = new Date(variant.eventEnd);
      const doorsOpen = new Date(
        getDoorsOpenTime({
          type: 'eventVariant',
          eventOrVariant: variant,
        }),
      );
      const isFuture = isBefore(currentDate, doorsOpen);
      const isLive =
        isBefore(doorsOpen, currentDate) && isBefore(currentDate, endDate);
      if (isFuture) {
        futureVariants.push(variant);
      } else if (isLive) {
        liveVariants.push(variant);
      } else {
        pastVariants.push(variant);
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn('Some variants were not found, we can not show them...'); // Easier to spot data migration related bugs
      liveVariants.push(variant);
    }
  }
  futureVariants.sort(sortVariants);
  pastVariants.sort(sortVariants);
  liveVariants.sort(sortVariants);

  return {
    futureVariants,
    liveVariants,
    pastVariants,
    notFoundVariants,
  };
}

export function getEarliestEventVariant(
  variants?: EventVariant[],
): EventVariant | undefined {
  if (!variants?.length) return undefined;
  return [...variants].sort(
    (a, b) =>
      /** If the eventStart is undefined, then this event variant will be pushed to the end of the array.
       * This prevents having the scenario where an event variant eventStart is null and it's returned as
       * the earliest variant */
      (a.eventStart ? new Date(a.eventStart).getTime() : Number.MAX_VALUE) -
      (b.eventStart ? new Date(b.eventStart).getTime() : Number.MAX_VALUE),
  )[0];
}

export function getCheapestEventVariant(
  variants?: EventVariant[],
): EventVariant | undefined {
  if (!variants?.length) return undefined;
  return [...variants].sort(
    (
      a,
      b, // order by price ascending
    ) =>
      // if price is undefined, give the highest number to the variant
      (a.price?.EUR || Number.MAX_VALUE) - (b.price?.EUR || Number.MAX_VALUE),
  )[0];
}
