'use client';

import React, { useMemo, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useGetEnvironmentVariable } from './useGetEnvironmentVariable';
export const useReCaptcha = () => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const {
    RECAPTCHA_SITE_KEY
  } = useGetEnvironmentVariable(['RECAPTCHA_SITE_KEY']);
  const getRecaptchaAndValidate = async (): Promise<string> => {
    const token = await recaptchaRef.current?.executeAsync();
    recaptchaRef.current?.reset();
    return token ?? '';
  };
  const ReCaptchaComponent = useMemo(() => {
    if (!RECAPTCHA_SITE_KEY) return;
    return <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_SITE_KEY} size="invisible" />;
  }, [RECAPTCHA_SITE_KEY]);
  return {
    ReCaptchaComponent,
    getRecaptchaAndValidate
  };
};