'use client';

import React, { useEffect } from 'react';
import { ContentfulEventCarousel } from '@/types/contentful';
import type { EventsByTag } from '@/types/page-context';
import { logPageViewToSegment } from '@/utils/segment';
import { SearchPageComponent } from './SearchComponent';
const SearchPage = ({
  location,
  carousels,
  eventsByTag
}: {
  location?: Location;
  carousels?: ContentfulEventCarousel[];
  eventsByTag?: EventsByTag;
}) => {
  useEffect(() => {
    logPageViewToSegment(`/search`);
  }, []);
  return <SearchPageComponent location={location} carousels={carousels} eventsByTag={eventsByTag} data-sentry-element="SearchPageComponent" data-sentry-component="SearchPage" data-sentry-source-file="index.tsx" />;
};
export default SearchPage;