import React, { ReactNode } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../-core/accordion';
import Icon from '../Icon';
const AccordionWrapper = ({
  type,
  items
}: {
  type: 'grouped' | 'separate';
  items: {
    title: string;
    content: ReactNode;
    icon?: string;
  }[];
}) => {
  const typeSpecificProps = type === 'grouped' ? {
    type: 'single' as const,
    defaultValue: 'item-0'
  } : {
    type: 'multiple' as const,
    collapsible: 'true'
  };
  return <Accordion {...typeSpecificProps} className="accordion" data-sentry-element="Accordion" data-sentry-component="AccordionWrapper" data-sentry-source-file="AccordionWrapper.tsx">
      {items.map((item, index) => <AccordionItem key={index} value={'item-' + index} className="accordion__item">
          <AccordionTrigger className="accordion__header">
            <div className="accordion__title">
              {item.icon && <Icon icon={item.icon} />}
              {item.title}
            </div>
          </AccordionTrigger>
          <AccordionContent className="accordion__content">
            {item.content}
          </AccordionContent>
        </AccordionItem>)}
    </Accordion>;
};
export default AccordionWrapper;