import React, { ChangeEvent, FC, MouseEvent } from 'react';
import { ButtonType } from '@/enums/ButtonType';
import { ValidationError, ValidationErrorCode } from '@/errors';
import { NotificationObject } from '@/types/Notification';
import Button from '../Button';
import Input from '../input';
import OnAirNotification from '../OnAirNotification';
import messages from './messages';
type ForgotPasswordFormComponentProps = {
  onEmailChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmitForm: () => void;
  onGoBackToLogin: (e?: MouseEvent<Element>) => void;
  notification?: NotificationObject;
  error?: ValidationError;
};
const ForgotPasswordFormComponent: FC<ForgotPasswordFormComponentProps> = ({
  onEmailChange,
  onSubmitForm,
  onGoBackToLogin,
  notification,
  error
}) => <div className="d-flex flex-column mx-auto" style={{
  maxWidth: '600px'
}} data-sentry-component="ForgotPasswordFormComponent" data-sentry-source-file="component.tsx">
    <h3 className="subtitle text-center mb-3">{messages.title}</h3>
    {notification && <OnAirNotification className="mb-3" text={notification.text} type={notification.type} />}
    <h4 className="body-text text-center mb-2">{messages.subtitle}</h4>
    <div className="d-flex flex-column justify-content-center">
      <Input className="mb-3" label={messages['enter-your-email-label']} icon="oa-email" onChange={onEmailChange} testId="forgotPasswordInput" onEnter={onSubmitForm} error={error?.code === ValidationErrorCode.invalidEmail && error.message} data-sentry-element="Input" data-sentry-source-file="component.tsx" />
      <Button className="mb-2" onClick={onSubmitForm} data-sentry-element="Button" data-sentry-source-file="component.tsx">
        {messages['primary-cta']}
      </Button>
      <Button type={ButtonType.OUTLINE_SECONDARY} onClick={onGoBackToLogin} data-sentry-element="Button" data-sentry-source-file="component.tsx">
        {messages['secondary-cta']}
      </Button>
    </div>
  </div>;
export default ForgotPasswordFormComponent;