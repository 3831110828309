import React, { FC } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import Icon from '../Icon';
import InputError from '../input/InputError';
import { UploadedFileComponent } from './UploadedFileComponent';
type Props = {
  registerFileInput: UseFormRegisterReturn;
  fileName?: string;
  onRemoveFile: () => void;
  errorMessage?: string;
};
export const FileUploader: FC<Props> = ({
  registerFileInput,
  fileName,
  onRemoveFile,
  errorMessage
}) => <div className="file-uploader__wrapper" data-sentry-component="FileUploader" data-sentry-source-file="FileUploader.tsx">
    <label htmlFor="fileUploader" className="custom-file-uploader">
      <div>
        <Icon icon="oa-expand" data-sentry-element="Icon" data-sentry-source-file="FileUploader.tsx" /> <p className="body-text">Attach file</p>
      </div>
    </label>
    <input data-testid="fileUploader" id="fileUploader" type="file" accept="image/png, image/jpeg, image/x-png, image/webp, application/pdf" {...registerFileInput} />
    <UploadedFileComponent onRemoveFile={onRemoveFile} text={fileName} data-sentry-element="UploadedFileComponent" data-sentry-source-file="FileUploader.tsx" />
    {errorMessage && <InputError testId="fileUploaderError">{errorMessage}</InputError>}
  </div>;