'use client';

import type { BodyInit } from 'node-fetch';

import { getEnvironmentVariableServerSide } from '../../../next/src/utils/server-functions/getEnvServerSide';
import { genericAPIMessages } from '../messages/genericAPI';
import fetch from './fetch';

export interface ContactData {
  type: number; // Aka Category, see categoryOptions in lib
  email: string;
  subject: string;
  description: string;
  attachment?: string | Blob;
  customerJwt?: string;
  reCaptchaToken: string;
  deviceInfo: {
    platform: string;
    browser: string;
    isMobile: string;
  };
}

export async function fetchSupportTicket({
  type,
  email,
  subject,
  description,
  attachment,
  customerJwt,
  deviceInfo,
  reCaptchaToken,
}: ContactData) {
  const { ONAIR_ENDPOINT } = await getEnvironmentVariableServerSide([
    'ONAIR_ENDPOINT',
  ]);
  const freshdeskSupportEndpoint = `${ONAIR_ENDPOINT!}/support-ticket`;

  const formData = new FormData();
  formData.append('type', `${type}`);
  formData.append('email', email);
  formData.append('subject', subject);
  formData.append('description', description);

  formData.append('platform', deviceInfo.platform);
  formData.append('browser', deviceInfo.browser);
  formData.append('isMobile', deviceInfo.isMobile);
  formData.append('reCaptchaToken', reCaptchaToken);

  if (customerJwt) {
    formData.append('jwt', customerJwt);
  }
  if (attachment) {
    formData.append('attachment', attachment);
  }

  const response: any = await fetch(freshdeskSupportEndpoint, {
    method: 'POST',
    // Hack to compile with RN's ES2019 lib
    body: formData as unknown as BodyInit,
  });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { error, data } = await response.json();

  if (!response.ok || error) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const errorMessage = error || genericAPIMessages.error_performing_request;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    throw new Error(errorMessage);
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return data;
}
