'use client';

import React, { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import * as EmailValidator from 'email-validator';
import { useDispatch } from 'react-redux';
import { sendResetPasswordEmailAction } from '@/actions/forgotPasswordActions';
import { VALIDATION_ERROR_MESSAGES, ValidationError, ValidationErrorCode } from '@/errors';
import { forgotPasswordActions, ForgotPasswordStatus } from '@/reducers/forgotPasswordSlice';
import type { AppDispatch } from '@/state/createStore';
import ROUTES from '../../constants/routes';
import useRedirectIfLoggedIn from '../../hooks/useRedirectIfLoggedIn';
import { useAppSelector } from '../../state/hooks';
import { setItemInLocalStorage } from '../../state/localStorage';
import { goToRoute } from '../../utils/navigation';
import ForgotPasswordFormComponent from './component';
import FORGOT_PASSWORD_STORED_EMAIL from './constants';
const ForgotPasswordForm: FC = () => {
  useRedirectIfLoggedIn(ROUTES.myPlace);
  const [email, setEmail] = useState<string>('');
  const [localError, setLocalError] = useState<ValidationError | undefined>(undefined);
  const dispatch = useDispatch<AppDispatch>();
  const notification = useAppSelector(({
    forgotPassword
  }) => forgotPassword.notification);
  const forgotPasswordStatus = useAppSelector(({
    forgotPassword
  }) => forgotPassword.status);
  useEffect(() => {
    if (forgotPasswordStatus === ForgotPasswordStatus.sendResetPasswordEmailSuccessful) {
      // We will need the email for login him once he updates his password
      setItemInLocalStorage(FORGOT_PASSWORD_STORED_EMAIL, email);
      goToRoute(ROUTES.forgotPasswordCheckYourEmail);
      // Resetting the state to avoid an infinite loop when returning to this page
      dispatch(forgotPasswordActions.resetState());
    }
  }, [dispatch, email, forgotPasswordStatus]);
  const handleSubmitForm = useCallback(async () => {
    setLocalError(undefined);
    dispatch(forgotPasswordActions.resetState());
    if (email && EmailValidator.validate(email)) {
      dispatch(sendResetPasswordEmailAction(email));
    } else {
      setLocalError(new ValidationError(ValidationErrorCode.invalidEmail, VALIDATION_ERROR_MESSAGES[ValidationErrorCode.invalidEmail]));
    }
  }, [dispatch, email]);
  return <ForgotPasswordFormComponent onEmailChange={e => setEmail(e.target.value)} onSubmitForm={handleSubmitForm} onGoBackToLogin={() => goToRoute(ROUTES.login)} notification={notification} error={localError} data-sentry-element="ForgotPasswordFormComponent" data-sentry-component="ForgotPasswordForm" data-sentry-source-file="index.tsx" />;
};
export default ForgotPasswordForm;