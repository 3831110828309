'use client';

import React, { FC } from 'react';
import { Event } from '@/types/contentful';
import { useEventConfigurator } from '@/utils/eventConfigurator';
import { useCountryCodeSelector } from '../../state/hooks';
import { localizedDateFormat } from '../../utils/date';
import HeroEventConfiguratorBroken from './HeroEventConfiguratorBroken';
import HeroEventConfiguratorElement from './HeroEventConfiguratorElement';
type HeroEventConfiguratorType = {
  event: Event;
  hasTicketInShopify?: boolean;
};
const HeroEventConfigurator: FC<HeroEventConfiguratorType> = ({
  event,
  hasTicketInShopify
}) => {
  const {
    variants,
    type
  } = event;
  const {
    selectedVariant
  } = useEventConfigurator({
    variants
  });
  const customerCountryCode = useCountryCodeSelector();
  const today = new Date();
  const notReleased = selectedVariant?.eventStart && new Date(selectedVariant.eventStart) > today;
  // if the video is of type 'vod', it has start time, the release date is NOT passed and the user
  // is NOT logged in or has NOT buy the show then display the release date
  const releaseDate = !hasTicketInShopify && type === 'vod' && notReleased ? localizedDateFormat(new Date(selectedVariant.eventStart), 'd MMMM yyyy, HH:mm', customerCountryCode, false) : undefined;
  if (variants.length === 0) {
    return <HeroEventConfiguratorBroken />;
  }
  return <HeroEventConfiguratorElement event={event} releaseDate={releaseDate} data-sentry-element="HeroEventConfiguratorElement" data-sentry-component="HeroEventConfigurator" data-sentry-source-file="HeroEventConfigurator.tsx" />;
};
export default HeroEventConfigurator;