'use client';

import React from 'react';
import type { FC } from 'react';
import { useParams } from 'next/navigation';
import ROUTES from '../../constants/routes';
import { AVAILABLE_LANGUAGES } from '../constants';
import MyPlaceIndex from './my-place-index/MyPlaceIndex';
import MyPlaceTickets from './my-tickets/MyTickets';
import MyPlaceChangePassword from './MyPlaceChangePassword';
import MyPlaceProfileSettings from './profile-settings';
import ChangeEmail from './profile-settings/change-email/ChangeEmail';
import ChangeProfile from './profile-settings/change-profile/ChangeProfile';
import SideNavigation from './SideNavigation';
const MyPlace: FC = () => {
  const {
    id
  } = useParams();

  // if id is an array, it means we're on a subpage. Join the array to get the full path
  let slug = Array.isArray(id) ? id.join('/') : id;
  const locale = AVAILABLE_LANGUAGES.find(language => slug.startsWith(language));
  if (locale) {
    slug = slug.slice(locale.length + 1); // Also remove the slash
  }
  return <div className="row flex-column flex-md-row" data-sentry-component="MyPlace" data-sentry-source-file="MyPlace.tsx">
      <div className="col-4 d-none d-md-block">
        <SideNavigation data-sentry-element="SideNavigation" data-sentry-source-file="MyPlace.tsx" />
      </div>

      <div className="col-12 col-md-8 my-place-content">
        {slug.includes('my-place/profile-settings') && <MyPlaceProfileSettings path={ROUTES.profileSettings}>
            {slug === 'my-place/profile-settings' && <ChangeProfile path="/" />}

            {slug === 'my-place/profile-settings/change-email' && <ChangeEmail path={ROUTES.changeEmail} />}

            {slug === 'my-place/profile-settings/change-password' && <MyPlaceChangePassword path={ROUTES.changePassword} />}
          </MyPlaceProfileSettings>}

        {slug === 'my-place/shows' && <MyPlaceTickets path={ROUTES.shows} />}

        {slug === 'my-place' && <MyPlaceIndex path="/" />}
      </div>
    </div>;
};
export default MyPlace;