/* eslint-disable import/no-unresolved */
/* eslint-disable unicorn/no-null */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useState } from 'react';

import { useAppSelector } from '@/state/hooks';
import { pushToElevarDataLayer } from '@/utils/pushToElevarDataLayer';
import { algoliaSearchServerSide } from '@/utils/server-functions/algoliaSearchServerSide';
import { contentfulEventsBySlugsServerSide } from '@/utils/server-functions/contentfulEventsBySlugsServerSide';
import { ContentfulAsset, ContentfulImage } from '../types/contentful';

interface UseAlgoliaSearchProps {
  query: string;
}

export interface AlgoliaResult {
  objectID: string;
  fields: {
    slug: {
      'en-US': string;
    };
  };
}

interface Result {
  carouselLogo?: ContentfulImage;
  eventInfoLogo?: ContentfulImage;
  portraitPoster?: ContentfulImage;
  poster: ContentfulImage;
  shortTitle?: string;
  slug: string;
  sys: {
    id: string;
  };
  title: string;
}

export const useAlgoliaSearch = ({ query }: UseAlgoliaSearchProps) => {
  const [algoliaResults, setAlgoliaResults] = useState<AlgoliaResult[]>([]);
  const [results, setResults] = useState<Result[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any | null>(null);
  const auth = useAppSelector((state) => state.auth);
  const cartTotal = useAppSelector((state) => state.cartSlice.cartTotalAmmount);

  const reset = () => {
    setResults([]);
    setError(null);
  };

  useEffect(() => {
    if (query === '') {
      reset();
      return undefined;
    }
    const search = async () => {
      setIsLoading(true);
      try {
        const hits = await algoliaSearchServerSide({ query });
        const impressions = hits.map(
          (
            {
              title,
              productTitle,
              portraitImage,
              slug,
            }: {
              title: string;
              productTitle: string;
              portraitImage: { asset: ContentfulAsset };
              slug: string;
            },
            index: number,
          ) => {
            return {
              id: '123123',
              name: title,
              brand: 'OnAir',
              category: 'Dummy category',
              variant: productTitle,
              price: '10',
              compare_at_price: '10.0',
              quantity: '1',
              list: window.location.pathname,
              product_id: '123123',
              variant_id: '123123',
              image: portraitImage.asset.url,
              url: slug,
              position: index + 1,
            };
          },
        );
        pushToElevarDataLayer({
          eventName: 'dl_view_search_results',
          auth: auth.token,
          cartTotal: cartTotal || '0',
          ecommerce: {
            currencyCode: 'EUR',
            actionField: {
              list: 'search results',
            },
            impressions,
          },
        });

        setAlgoliaResults(hits);
        if (hits?.length === 0) setError('No results');
      } catch (_error) {
        setError(_error);
      } finally {
        setIsLoading(false);
      }
    };
    const delayDebounceFn = setTimeout(() => {
      search();
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [query]);

  useEffect(() => {
    const fetchContentfulData = async () => {
      const responseData = await contentfulEventsBySlugsServerSide({
        algoliaResults,
      });

      setResults(responseData);
    };
    fetchContentfulData();
  }, [algoliaResults]);

  return { results, isLoading, error };
};
