import { template } from 'lodash';

import { VALIDATION_ERROR_MESSAGES, ValidationErrorCode } from '@/errors';
import { commonTexts } from '@/messages/common';
import { DATE_INPUT_NAMES } from './constants';
import { YearValidationRules } from './types';

const invalidDayMsg = template(
  VALIDATION_ERROR_MESSAGES[ValidationErrorCode.invalid],
)({ field: commonTexts.day });

// For example February from 2020 will return 29, getDaysInMonth(1, 2020) = 29
export const getDaysInMonth = (month: number, year: number) =>
  new Date(year, month + 1, 0).getDate();

export const registerDayInputParams = {
  name: DATE_INPUT_NAMES.day,
  generateOptions: (month: number, year: number) => ({
    min: 1,
    max: 31,
    minLength: 1,
    maxLength: 2,
    required: true,
    validate: {
      validDay: (day: number) =>
        getDaysInMonth(month, year) >= day || invalidDayMsg,
    },
  }),
};

export const registerYearInputParams = {
  name: DATE_INPUT_NAMES.year,
  generateOptions: ({ min, max, required, validate }: YearValidationRules) => ({
    min,
    max,
    required,
    minLength: 4,
    maxLength: 4,
    validate,
  }),
};

export const getDateFormatted = (
  day?: string,
  month?: string,
  year?: string,
): string => {
  if (day !== undefined && month !== undefined && year !== undefined) {
    const monthFormatted = (parseInt(month, 10) + 1).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
    });
    const dayFormatted = parseInt(day, 10).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
    });
    return `${year}-${monthFormatted}-${dayFormatted}`;
  }
  return '';
};
