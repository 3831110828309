//@ts-nocheck
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as EmailValidator from 'email-validator';
import { template } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { ButtonType } from '@/enums/ButtonType';
import { NotificationType } from '@/enums/Notification';
import { VALIDATION_ERROR_MESSAGES, ValidationErrorCode } from '@/errors';
import { commonTexts } from '@/messages/common';
import { loginWithEmailAndPassword, register } from '@/reducers/authSlice';
import { pushToElevarDataLayer } from '@/utils/pushToElevarDataLayer';
import ROUTES from '../../constants/routes';
import { useReCaptcha } from '../../hooks/useReCaptcha';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { charactersAndSpacesRegex } from '../../utils/form';
import { isObjectEmpty } from '../../utils/object';
import Button from '../Button';
import Checkbox from '../Checkbox';
import DateInput from '../date-input/DateInput';
import Icon from '../Icon';
import Input from '../input';
import LinkExternal from '../LinkExternal';
import LinkInternal from '../LinkInternal';
import OnAirNotification from '../OnAirNotification';
import { getDateOfBirthErrorMessage, getRegisterSerializedFormatData, getRegisterYearValidationRules } from './login-register-utils';
import messages from './messages';
import { RequestStatus } from './RegisterTypes';
import type { SerializedRegisterFormData } from './RegisterTypes';
interface RegisterScreenProps {
  multipassStatus?: RequestStatus;
}
export default ({
  multipassStatus
}: RegisterScreenProps) => {
  const registerStatus = useAppSelector(state => state.auth.registerStatus);
  const loginStatus = useAppSelector(state => state.auth.loginStatus);
  const registerError = useAppSelector(state => state.auth.registerError);
  const dispatch = useAppDispatch();
  const auth = useAppSelector(state => state.auth);
  const {
    ReCaptchaComponent,
    getRecaptchaAndValidate
  } = useReCaptcha();
  const methods = useForm();
  const {
    register: registerInput,
    handleSubmit,
    formState
  } = methods;
  const formRef = useRef<HTMLFormElement>(null);
  const {
    errors: validationErrors
  } = formState;
  const [serializedFormData, setSerializedFormData] = useState<SerializedRegisterFormData>();
  const handleChangeForm = useCallback(() => {
    if (formRef.current) {
      setSerializedFormData(getRegisterSerializedFormatData(formRef.current));
    }
  }, [setSerializedFormData]);
  const handleSubmitForm = useCallback(async () => {
    if (formRef.current) {
      /* Not reading 'serializedFormData' from the props because the changes
            in the input "month" are not triggering a change on the form, so it's not getting saved
            until the form gets submitted */
      const serializedData = getRegisterSerializedFormatData(formRef.current);
      const reCaptchaToken = await getRecaptchaAndValidate();
      if (isObjectEmpty(validationErrors) && !isObjectEmpty(serializedData)) {
        const {
          day,
          month,
          year,
          ...registerParams
        } = serializedData;
        if (registerParams.acceptsMarketing) {
          pushToElevarDataLayer({
            eventName: 'dl_subscribe',
            lead_type: 'email',
            auth: auth.token,
            email: registerParams.email
          });
        }
        dispatch(register({
          ...registerParams,
          reCaptchaToken: reCaptchaToken ?? ''
        }));
      }
    }
  }, [validationErrors, formRef, dispatch, getRecaptchaAndValidate]);
  useEffect(() => {
    if (registerStatus === 'REGISTER_COMPLETE') {
      const {
        email,
        password
      } = serializedFormData || {};
      if (email && password) {
        dispatch(loginWithEmailAndPassword({
          email,
          password
        }));
      }
    }
  }, [dispatch, registerStatus, serializedFormData]);
  const onSubmitForm = handleSubmit(handleSubmitForm);
  return <>
      <h5 className="subtitle text-center">
        {messages.other.create_an_account}
      </h5>
      <p className="body-text mt-2 text-center">
        {messages.other.register_cta_text}
      </p>
      <p className="caption-text text-center mt-2 d-block d-lg-none">
        Already have an account?{' '}
        <LinkInternal to="/login/">Login here</LinkInternal>
      </p>

      <hr className="mt-5" />

      {registerError?.code === 'UNEXPECTED' || registerError?.code === 'ERROR_RESPONSE' ? <OnAirNotification className="mt-2" text={registerError.message} type={NotificationType.error} /> : null}

      {registerStatus === 'REGISTER_COMPLETE' ? <OnAirNotification className="mt-2" text="Register successfull, logging you in..." type={NotificationType.success} /> : null}

      <FormProvider {...methods}>
        <form autoComplete="off" onChange={handleChangeForm} ref={formRef} onSubmit={onSubmitForm} className="m-0">
          <div className="row mt-2">
            <div className="col-12 col-md-6">
              <Input label={commonTexts.first_name} onEnter={onSubmitForm} icon="oa-user" autoGeneratedProps={registerInput('firstName', {
              required: {
                value: true,
                message: template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.required])({
                  field: commonTexts.first_name
                })
              },
              pattern: {
                value: charactersAndSpacesRegex,
                message: template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.invalid])({
                  field: commonTexts.first_name
                })
              },
              maxLength: {
                value: 255,
                message: template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.tooLong])({
                  field: commonTexts.first_name
                })
              }
            })} error={validationErrors.firstName?.message as string} testId="firstNameInput" />
            </div>
            <div className="col-12 col-md-6">
              <Input label={commonTexts.last_name} onEnter={onSubmitForm} autoGeneratedProps={registerInput('lastName', {
              required: {
                value: true,
                message: template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.required])({
                  field: commonTexts.last_name
                })
              },
              pattern: {
                value: charactersAndSpacesRegex,
                message: template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.invalid])({
                  field: commonTexts.last_name
                })
              },
              maxLength: {
                value: 255,
                message: template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.tooLong])({
                  field: commonTexts.last_name
                })
              }
            })} error={validationErrors.lastName?.message as string} testId="lastNameInput" />
            </div>
          </div>

          <p className="body-text mt-2 uppercase-first-letter">
            {commonTexts.date_of_birth}
          </p>

          <DateInput error={getDateOfBirthErrorMessage(validationErrors.year?.type ?? validationErrors.day?.type)} yearValidationRules={getRegisterYearValidationRules(serializedFormData?.dateOfBirth)} />

          <Input className="mt-2" label={commonTexts.email_address} onEnter={onSubmitForm} icon="oa-email" autoGeneratedProps={registerInput('email', {
          required: {
            value: true,
            message: template(VALIDATION_ERROR_MESSAGES[`${ValidationErrorCode.required}-an-word`])({
              field: commonTexts.email_address
            })
          },
          validate: {
            validEmail: (value: string) => EmailValidator.validate(value) || VALIDATION_ERROR_MESSAGES[ValidationErrorCode.invalidEmail]
          }
        })} error={validationErrors.email?.message as string} testId="emailInput" />
          <Input className="mt-2" label={commonTexts.password} type="password" icon="oa-lock" onEnter={onSubmitForm} autoGeneratedProps={registerInput('password', {
          required: {
            value: true,
            message: template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.required])({
              field: commonTexts.password
            })
          },
          minLength: {
            value: 5,
            message: template(VALIDATION_ERROR_MESSAGES[ValidationErrorCode.notLongEnough])({
              field: commonTexts.password,
              amount: 5
            })
          }
        })} error={validationErrors.password?.message as string} testId="passwordInput" />

          <Checkbox className="mt-2" id="register-marketing" label={commonTexts.subscribe_to_news} name="acceptsMarketing" />

          <Checkbox className="mt-1" id="register-privacy-policy" testId="acceptsTermsAndConditions" error={(validationErrors.acceptsTermsAndConditions ?? registerError?.code === 'INVALID_TERMS_AND_CONDITIONS') && validationErrors.acceptsTermsAndConditions?.message as string} autoGeneratedProps={registerInput('acceptsTermsAndConditions', {
          required: {
            value: true,
            message: messages.register.error_please_accept_our_terms_and_conditions
          }
        })}>
            <div>
              {commonTexts.accept_terms_and_conditions_1}
              <LinkExternal to={ROUTES.termsAndConditions}>
                {commonTexts.accept_terms_and_conditions_2}
              </LinkExternal>
            </div>
          </Checkbox>
          {ReCaptchaComponent}
        </form>
      </FormProvider>

      <Button className="mt-5" type={ButtonType.SOLID_PRIMARY} grow onClick={onSubmitForm} loading={registerStatus === 'IN_PROGRESS' || loginStatus === 'IN_PROGRESS' || multipassStatus === RequestStatus.PENDING} testId="submitButton">
        {messages.other.register}
      </Button>
    </>;
};