import React, { FC } from 'react';
import Button from '../Button';
import LinkInternal from '../LinkInternal';
import EmailSent from '../svgs/EmailSent';
import messages from './messages';
const CheckYourEmailComponent: FC = () => <div className="d-flex flex-column align-items-center" data-sentry-component="CheckYourEmailComponent" data-sentry-source-file="component.tsx">
    <h3 className="subtitle text-center">{messages.title}</h3>
    <div className="my-3">
      <EmailSent data-sentry-element="EmailSent" data-sentry-source-file="component.tsx" />
    </div>
    <h4 className="body-text text-center mb-2">{messages.body}</h4>
    <div className="d-flex flex-column justify-content-center mx-auto" style={{
    maxWidth: '600px'
  }}>
      <Button className="mb-3" onClick={() => window.open('message://')} data-sentry-element="Button" data-sentry-source-file="component.tsx">
        {messages['primary-cta']}
      </Button>
      <div className="caption-text text-center" style={{
      width: '300px'
    }}>
        <p className="block my-0 mb-1">{messages['help-text']}</p>
        <p className="block my-0">
          {messages['help-text2']}
          <LinkInternal className="caption-text" to="/forgot-password" data-sentry-element="LinkInternal" data-sentry-source-file="component.tsx">
            {messages['try-another-email-link']}
          </LinkInternal>
        </p>
      </div>
    </div>
  </div>;
export default CheckYourEmailComponent;