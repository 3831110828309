/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { FC } from 'react';
import { EventVariant } from '@/types/contentful';
import { formatDisplayPrice } from '@/utils/i18n';
import { CartStatus } from '../../state/reducers/cartSlice';
import { localizedDateFormat } from '../../utils/date';
import Button from '../Button';
import Icon from '../Icon';
import messages from './messages';
type StickyHeroEventInfoProps = {
  selectedVariant: EventVariant;
  customerCountryCode: string;
  cartStatus?: CartStatus;
  handleBuyTicketNow?: () => void;
  eventTitle: string;
};
const StickyHeroEventInfo: FC<StickyHeroEventInfoProps> = ({
  selectedVariant,
  customerCountryCode,
  cartStatus,
  handleBuyTicketNow,
  eventTitle
}) => {
  const eventStart = selectedVariant.eventStart ? new Date(selectedVariant.eventStart) : null;
  return <div className="container" data-sentry-component="StickyHeroEventInfo" data-sentry-source-file="StickyHeroEventInfo.tsx">
      <div className="row d-flex sticky-event-configurator__content">
        <div className="col-md-12">
          <div className="row d-flex wrapper">
            <div className="left-column mb-2 mb-md-0 col-lg-6 col-md-8">
              <div className="event-title d-none d-sm-block">{eventTitle}</div>
              <div className="d-flex event-info justify-content-center justify-content-md-start">
                <div className="icons">
                  {selectedVariant?.icons?.map(({
                  type: iconName
                }) => <Icon key={`icon-${iconName}`} icon={iconName} />)}
                </div>

                {eventStart && eventStart > new Date() && <>
                    <span className="dot d-none d-md-block" />
                    <div className="event-date d-none d-md-block">
                      {localizedDateFormat(eventStart, 'EEEE d MMMM yyyy, HH:mm', customerCountryCode)}
                    </div>
                  </>}
                <span className="dot d-none d-sm-block" />
                <div className="event-price">
                  <span>
                    {selectedVariant.price && formatDisplayPrice(customerCountryCode, selectedVariant.price)}
                  </span>
                </div>
              </div>
            </div>
            <div className="right-column col-md-3">
              <Button className="mw-80 ml-auto" icon="oa-ticket" grow onClick={handleBuyTicketNow} loading={cartStatus === 'CREATE_CART_PENDING' || cartStatus === 'ADD_CART_LINES_PENDING'} data-sentry-element="Button" data-sentry-source-file="StickyHeroEventInfo.tsx">
                {messages.buyShowNow}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>;
};
export default StickyHeroEventInfo;