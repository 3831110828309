import React, { FC } from 'react';
import { commonTexts } from '@/messages/common';
import { formatNumberWithTwoDigits } from '@/utils/format';
import { CountDown, useCountDown } from '@/utils/useCountdown';
type FutureEventCountdownProps = {
  startDate: Date;
};
type FutureEventCountdownComponentProps = {
  timeUntil: CountDown;
};
type TimeAndUnitComponentProps = {
  timeLeft: string;
  short: string;
};
const TimeAndUnitComponent: FC<TimeAndUnitComponentProps> = ({
  timeLeft,
  short
}) => <>
    <span className="title">{timeLeft}</span>
    <small className="body-title"> {short} </small>
  </>;
export const FutureEventCountdownComponent: FC<FutureEventCountdownComponentProps> = ({
  timeUntil
}) => <div className="future-event-cd-wraper" data-sentry-component="FutureEventCountdownComponent" data-sentry-source-file="FutureEventCountDown.tsx">
    {timeUntil.days > 0 && <TimeAndUnitComponent timeLeft={formatNumberWithTwoDigits(timeUntil.days)} short={commonTexts.days} />}
    {timeUntil.hours > 0 && <TimeAndUnitComponent timeLeft={formatNumberWithTwoDigits(timeUntil.hours)} short={commonTexts.hours_short} />}
    {timeUntil.minutes > 0 && <TimeAndUnitComponent timeLeft={formatNumberWithTwoDigits(timeUntil.minutes)} short={commonTexts.minutes_short} />}
    {timeUntil.seconds > 0 && <TimeAndUnitComponent timeLeft={formatNumberWithTwoDigits(timeUntil.seconds)} short={commonTexts.second_short} />}
    {timeUntil.days === 0 && timeUntil.hours === 0 && timeUntil.minutes === 0 && timeUntil.seconds === 0 && <TimeAndUnitComponent timeLeft={formatNumberWithTwoDigits(0)} short={commonTexts.second_short} />}
  </div>;
const FutureEventCountdown: FC<FutureEventCountdownProps> = ({
  startDate
}) => {
  const timeUntil = useCountDown(startDate);
  return timeUntil ? <FutureEventCountdownComponent timeUntil={timeUntil} data-sentry-element="FutureEventCountdownComponent" data-sentry-component="FutureEventCountdown" data-sentry-source-file="FutureEventCountDown.tsx" /> : null;
};
export default FutureEventCountdown;