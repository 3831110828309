//@ts-nocheck
import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { EventsState } from '@/reducers/eventsSlice';
import { Event } from '@/types/contentful';
import { getEventCategories } from '@/utils/events';
import { useAppSelector } from '../../../state/hooks';
import TicketsComponent from './MyTicketsComponent';
export interface MyTicketsProps {
  path: string;
}
const MyTickets: FC<MyTicketsProps> = () => {
  const [pastTickets, setPastTickets] = useState<Event[]>([]);
  const [futureTickets, setFutureTickets] = useState<Event[]>([]);
  const events: Event[] = useAppSelector(({
    tickets
  }) => tickets.events);
  const fetchStatus: EventsState['status'] = useAppSelector(({
    tickets
  }) => tickets.status);
  const error: EventsState['error'] = useAppSelector(({
    tickets
  }) => tickets.error);
  useEffect(() => {
    if (events.length > 0) {
      const {
        pastEvents,
        futureEvents,
        liveEvents
      } = getEventCategories(events);
      setPastTickets(pastEvents);
      setFutureTickets([...futureEvents, ...liveEvents]);
    }
  }, [events]);
  return <TicketsComponent error={error} fetchStatus={fetchStatus} futureTickets={futureTickets} pastTickets={pastTickets} data-sentry-element="TicketsComponent" data-sentry-component="MyTickets" data-sentry-source-file="MyTickets.tsx" />;
};
export default MyTickets;