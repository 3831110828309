'use client';

import { useEffect, useState } from 'react';

interface useStreamUrlsCheckProps {
  isLive?: boolean;
  hasAccess?: boolean;
  stream?: {
    dashUrl?: string;
    hlsUrl?: string;
    dashCastUrl?: string;
  };
}

export const useStreamUrlsCheck = ({
  isLive,
  hasAccess,
  stream: { dashUrl, hlsUrl, dashCastUrl } = {},
}: useStreamUrlsCheckProps) => {
  const [streamUrlsError, setStreamUrlsError] = useState(false);

  useEffect(() => {
    if (isLive && hasAccess && !dashUrl && !hlsUrl && !dashCastUrl) {
      return setStreamUrlsError(true);
    }
    return setStreamUrlsError(false);
  }, [hasAccess, dashUrl, hlsUrl, dashCastUrl, setStreamUrlsError, isLive]);

  return streamUrlsError;
};
