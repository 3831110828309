import React from 'react';
import { Event } from '@/types/contentful';
import ROUTES from '../../../constants/routes';
import { CodedError } from '../../../types/error';
import { ErrorComponent, ErrorOption } from '../../error';
import LinkInternal from '../../LinkInternal';
import messages from './messages';
type Props = {
  error: CodedError;
  event: Event;
};
const EventError = ({
  error,
  event
}: Props) => {
  const {
    title,
    message,
    option_title,
    option_refresh,
    option_contact,
    option_wait
  } = messages.error;
  const details = `${error?.name} (${error?.code}) - ${error?.message}`;
  return <div className="error-wrap" data-sentry-component="EventError" data-sentry-source-file="EventError.tsx">
      <ErrorComponent title={title} message={message} details={details} optionsTitle={option_title} isEventError data-sentry-element="ErrorComponent" data-sentry-source-file="EventError.tsx">
        <ErrorOption icon="oa-restart" data-sentry-element="ErrorOption" data-sentry-source-file="EventError.tsx">{option_refresh}</ErrorOption>
        <ErrorOption icon="oa-loader" data-sentry-element="ErrorOption" data-sentry-source-file="EventError.tsx">{option_wait}</ErrorOption>
        <ErrorOption icon="oa-email" data-sentry-element="ErrorOption" data-sentry-source-file="EventError.tsx">
          <LinkInternal to={`${ROUTES.contact}/?category=0&subject=${encodeURI(`Error on page /${event.slug}`)}&message=${encodeURI(`An error occured on page /${event.slug}\n\nDetails: ${details}`)}`} data-sentry-element="LinkInternal" data-sentry-source-file="EventError.tsx">
            {option_contact}
          </LinkInternal>
        </ErrorOption>
      </ErrorComponent>
    </div>;
};
export default EventError;