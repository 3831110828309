import { SocialMediaType } from '../enums/SocialMediaPlatforms';

export const getUrlOfSocialMediaPost = (
  eventName: string,
  social: SocialMediaType,
) => {
  const shareURL = 'https://www.onair.events';
  const postContent = `I watched ${eventName}, check it out at`;

  switch (social) {
    case SocialMediaType.FACEBOOK: {
      const facebookParameters = [];
      facebookParameters.push(
        `u=${encodeURI(shareURL)}`,
        `quote=${encodeURI(postContent)}`,
      );
      return `https://www.facebook.com/sharer/sharer.php?${facebookParameters.join(
        '&',
      )}`;
    }
    case SocialMediaType.TWITTER: {
      return `https://twitter.com/intent/tweet?text=${postContent} onair.events`;
    }
    default: {
      return '';
    }
  }
};
