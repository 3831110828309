import React, { ChangeEvent, FC, ReactNode } from 'react';
import classNames from 'classnames';
import InputError from './input/InputError';
export interface CheckboxProps {
  label?: string;
  id: string;
  disabled?: boolean;
  defaultChecked?: boolean;
  error?: boolean | string;
  className?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  testId?: string;
  autoGeneratedProps?: any;
  children?: ReactNode;
}
export const Checkbox: FC<CheckboxProps> = ({
  label,
  id,
  disabled,
  defaultChecked,
  error,
  className,
  onChange,
  name = 'checkbox',
  testId,
  autoGeneratedProps,
  children
}) => <div className={classNames('checkbox', {
  'checkbox-error': error,
  'checkbox-disabled': disabled
})} data-sentry-component="Checkbox" data-sentry-source-file="Checkbox.tsx">
    <input className={classNames('checkbox__ghost')} type="checkbox" id={id} name={name} disabled={disabled} defaultChecked={defaultChecked} onChange={e => {
    if (onChange) onChange(e);
  }} data-testid={testId} {...autoGeneratedProps || {}} />
    <label className={classNames('checkbox__label body-text', className)} htmlFor={id}>
      <div className="checkbox__styled" />
      {children ?? label}
    </label>
    {typeof error === 'string' && error && <InputError testId={testId ? `${testId}Error` : ''}>{error}</InputError>}
  </div>;
export default Checkbox;