// This could easily be a d.ts file, but Storybook isn't that happy about it and I don't know why :(

/**
 * Key codes matching event.code
 */
export const enum KeyboardKeyCode {
  Space = 'Space',
  Escape = 'Escape',
  Enter = 'Enter',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  Tab = 'Tab',
}

/**
 * Key codes matching event.key
 */
export const enum KeyboardKey {
  Space = ' ',
  Enter = 'Enter',
}
