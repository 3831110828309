//@ts-nocheck
'use client';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { ButtonType } from '@/enums/ButtonType';
import { loginWithEmailAndPassword } from '@/reducers/authSlice';
import type { FocusedRoute } from '@/state/reducers/navigationSlice';
import { createNavigationParams, navigateActions } from '@/state/reducers/navigationSlice';
import { getEnvironmentVariableServerSide } from '@/utils/server-functions/getEnvServerSide';
import ROUTES from '../../constants/routes';
import { emptyCart } from '../../state/actions/cartActions';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { handleLogout } from '../../state/tokenHelper';
import { navigate } from '../../utils/navigation';
import { isObjectEmpty } from '../../utils/object';
import { BackgroundImage } from '../BackgroundImage';
import Button from '../Button';
import { fetchMultipass } from '../cart/services';
import { getLoginReason } from './login-register-utils';
import LoginScreen from './LoginScreen';
import type { LoginReason, SerializedLoginFormDataType } from './LoginScreenTypes';
import messages from './messages';
import RegisterScreen from './RegisterScreen';
import { RequestStatus } from './RegisterTypes';
export interface LoginRegisterProps {
  registerFocussed?: boolean;
}
const LoginRegister: FC<LoginRegisterProps> = ({
  registerFocussed = false
}) => {
  const [loginFocussed, setLoginFocussed] = useState(!registerFocussed);
  const auth = useAppSelector(state => state.auth);
  const navigation = useAppSelector(state => state.navigation);
  const [status, setStatus] = useState(RequestStatus.IDLE);
  const [loginReason, setLoginReason] = useState<LoginReason>('DEFAULT');
  const [userCredentials, setUserCredentials] = useState<SerializedLoginFormDataType>({
    email: '',
    password: ''
  });
  const dispatch = useAppDispatch();

  /**
   * Method to handle navigation via button and pass focus to the appropriate route
   * on the navbar.
   */
  const handleButtonNavigation = (route: FocusedRoute) => {
    if (window.history) window.history.replaceState({}, '', route);
    dispatch(navigateActions.setFocusedRoute(route));
  };
  const {
    register: registerInput,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm();
  const focusLogin = () => {
    setLoginFocussed(true);
    handleButtonNavigation(ROUTES.login);
  };
  const focusRegister = () => {
    setLoginFocussed(false);
    if (navigation.loginReason === 'CHECKOUT' && navigation.checkoutUrl) {
      dispatch(createNavigationParams({
        state: {
          loginReason: navigation.loginReason,
          checkoutUrl: navigation.checkoutUrl
        }
      }));
      navigate(ROUTES.register);
    } else {
      handleButtonNavigation(ROUTES.register);
    }
  };
  useEffect(() => {
    // When the user has been redirected from a private route, we want to redirect them back to the /login page
    // When the user then pressed the back button in the browser, they will be redirected to the private route, which in turn redirects them to the /login page
    // This adds the home page as a history entry, so that the user will be redirected to the home page when pressing the back button while keeping the history state.
    if (navigation?.from === 'privateRoute') {
      window.history.pushState(window.history.state, '', '/');
      window.history.pushState(window.history.state, '', '/login');
    }
    return () => {
      dispatch(navigateActions.setFocusedRoute(undefined));
    };
  }, [dispatch, navigation?.from]);
  const handleMultipassLogin = useCallback(async (jwt: string) => {
    const checkoutUrl = navigation?.checkoutUrl;
    setStatus(RequestStatus.PENDING);
    try {
      const multiPassResult = await fetchMultipass(jwt, checkoutUrl);
      // If an error occured it could very well be due to a wrong session, redirect them back to the login and promt relog
      if (!multiPassResult || multiPassResult.error) {
        // eslint-disable-next-line no-console
        console.error('An error occurred signing in with multipass, logging the user out...');
        setStatus(RequestStatus.ERROR);
        handleLogout(dispatch);
        dispatch(createNavigationParams({
          state: {
            loginReason: 'ERROR',
            returnTo: ROUTES.cart
          }
        }));
        navigate(ROUTES.login);
      } else {
        const {
          SHOPIFY_DOMAIN
        } = await getEnvironmentVariableServerSide(['SHOPIFY_DOMAIN']);
        const multiPassLoginUrl = `${SHOPIFY_DOMAIN}/account/login/multipass/${multiPassResult.data.multipass}`;
        navigate(multiPassLoginUrl);
      }
    } catch (error) {
      setStatus(RequestStatus.ERROR);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [dispatch]);
  useEffect(() => {
    const reasonParameter = getLoginReason(navigation);
    setLoginReason(reasonParameter);
    if (auth.loginStatus === 'LOGGED_IN') {
      // Store user decision about remembering him/her or not
      if (reasonParameter === 'CHECKOUT' && navigation.checkoutUrl && auth.token?.jwt) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        handleMultipassLogin(auth.token.jwt);
      } else if (navigation?.returnTo) {
        navigate(navigation?.returnTo);
      } else if (reasonParameter === 'DELETE_ACCOUNT') {
        navigate(`${ROUTES.contact}?category=4`);
      } else {
        navigate(ROUTES.myPlace);
      }
    }
  }, [auth, handleMultipassLogin]);
  const formRef = useRef<HTMLFormElement>(null);
  const onSubmitForm = () => {
    const {
      email,
      password
    } = userCredentials;
    if (isObjectEmpty(errors) && email && password) {
      dispatch(emptyCart());
      dispatch(loginWithEmailAndPassword({
        email,
        password
      }));
    }
  };
  const handleInputChange = (key: keyof SerializedLoginFormDataType, value: string) => {
    setUserCredentials(previousState => ({
      ...previousState,
      [key]: value
    }));
  };
  return <BackgroundImage className="login-register__wrapper" imageUrl={'/assets/images/login-register-bg.png'} ariaLabel={'login background image'} data-sentry-element="BackgroundImage" data-sentry-component="LoginRegister" data-sentry-source-file="LoginRegister.tsx">
      <div className="login-register__introduction container-md justify-content-center">
        <div className="row d-flex justify-content-around h-100 align-content-center">
          <div className="col-4 text-center register-placeholder">
            <p className="subtitle">{messages.other.welcome_back}</p>
            <p className="body-text mt-2">{messages.other.login_cta_text}</p>
            <Button className="mt-2" grow onClick={focusLogin} type={ButtonType.SOLID_SECONDARY} data-sentry-element="Button" data-sentry-source-file="LoginRegister.tsx">
              {messages.login.log_in}
            </Button>
          </div>
          <div className="col-4 text-center login-placeholder">
            <p className="subtitle">{messages.other.welcome_to_on_air}</p>
            <p className="body-text mt-2">{messages.other.register_cta_text}</p>
            <Button className="mt-2" grow onClick={focusRegister} type={ButtonType.SOLID_SECONDARY} data-sentry-element="Button" data-sentry-source-file="LoginRegister.tsx">
              {messages.other.register}
            </Button>
          </div>
        </div>
      </div>

      <div className="login-register__overlay-wrapper container p-0">
        <div className={classNames('login-register__overlay', {
        'login-register__overlay--left': !loginFocussed
      })}>
          <div className="login-register__form-wrap">
            <div className={classNames('login-register__screen', {
            'login-register__screen--visible': loginFocussed
          })}>
              <LoginScreen onSubmitForm={handleSubmit(onSubmitForm)} onInputChange={handleInputChange} registerInput={registerInput} validationErrors={errors} formRef={formRef} multipassStatus={status} userCredentials={userCredentials} loginReason={loginReason} data-sentry-element="LoginScreen" data-sentry-source-file="LoginRegister.tsx" />
            </div>

            <div className={classNames('login-register__screen ', {
            'login-register__screen--visible': !loginFocussed
          })}>
              <RegisterScreen multipassStatus={status} data-sentry-element="RegisterScreen" data-sentry-source-file="LoginRegister.tsx" />
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>;
};
export default LoginRegister;