'use client';

import { useEffect } from 'react';

import { useAppSelector } from '../state/hooks';
import { goToRoute } from '../utils/navigation';

// If user is logged in, it redirects to the route supplied
const useRedirectIfLoggedIn = (route: string) => {
  const auth = useAppSelector((state) => state.auth);
  useEffect(() => {
    if (auth.loginStatus === 'LOGGED_IN') {
      goToRoute(route);
    }
  }, [auth, route]);
};

export default useRedirectIfLoggedIn;
