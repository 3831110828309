import type { FC, ReactNode } from 'react';
import dynamic from 'next/dynamic';
import classNames from 'classnames';
import { ContentfulEventHeroTheme } from '@/types/contentful';
import { BackgroundImage } from '../BackgroundImage';
const StreamPlayer = dynamic(() => import('@/components/hero/StreamHlsPlayer'), {
  suspense: true
});
export interface HeroProps {
  children: ReactNode;
  backgroundImageProps: {
    imageUrl: string;
    alt: string;
  };
  videoProps?: {
    videoUrl: string;
    thumbnailUrl: string;
  };
  colClassNames?: string;
  isCentered?: boolean;
  theme?: ContentfulEventHeroTheme;
  isEventHero?: boolean;
  layout?: 'left' | 'right' | 'center';
}
const HeroComponent: FC<HeroProps> = ({
  children,
  backgroundImageProps,
  videoProps,
  layout,
  // TODO: do we still need the following props?
  colClassNames = 'col-12 col-md-8 col-lg-7 col-xl-6',
  isCentered = false,
  theme = 'default',
  isEventHero
}) => <BackgroundImage className={classNames('hero-wrapper', `hero-wrapper--theme-${theme}`, {
  'hero-wrapper--event': isEventHero,
  'hero-wrapper--center-content': isCentered
})} noGradient imageUrl={videoProps ? videoProps.thumbnailUrl : backgroundImageProps.imageUrl} ariaLabel={backgroundImageProps.alt} layout={layout} data-sentry-element="BackgroundImage" data-sentry-component="HeroComponent" data-sentry-source-file="HeroComponent.tsx">
    {videoProps ? <StreamPlayer videoUrl={videoProps.videoUrl} /> : null}
    <div className="hero-wrapper__gradient">
      <div className="container h-100">
        <div className={classNames('row h-100', {
        'justify-content-center': isCentered
      })}>
          <div className={colClassNames}>{children}</div>
        </div>
      </div>
    </div>
    <div className="hero-wrapper__bottom-gradient" />
  </BackgroundImage>;
export default HeroComponent;