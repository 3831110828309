/* eslint-disable consistent-return */
import React, { FC } from 'react';
import { EventCarouselItem } from '../event-carousel/EventCarouselItem';
const SearchResults: FC<{
  results: any;
}> = ({
  results
}) => {
  if (!results || !results.length) return null;
  return <div className="container search-results" data-sentry-component="SearchResults" data-sentry-source-file="SearchResults.tsx">
      {results.map((result: any) => {
      if (!result?.slug) return;
      return <EventCarouselItem event={result} key={result.slug} displayDate={false} />;
    })}
    </div>;
};
export default SearchResults;