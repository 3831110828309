export const serializeForm = (
  form: HTMLFormElement,
): { [key: string]: any } => {
  const formEntries = new FormData(form);
  // @ts-ignore
  return Object.assign(...Array.from(formEntries, ([x, y]) => ({ [x]: y })));
};

/**
 * Allow:
 *  a-z
 *  A-Z
 *  special chars like ã å
 *  ' and -
 */
export const charactersAndSpacesRegex = /^\S([a-zA-Z \xC0-\uFFFF]*[-']*)*$/;
