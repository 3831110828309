import React, { FC } from 'react';
import Image from 'next/image';
import { NotificationType } from '@/enums/Notification';
import { ContentfulImage } from '@/types/contentful';
import OnAirNotification from '../../OnAirNotification';
import messages from './messages';
type NotForSaleProps = {
  image?: ContentfulImage;
};
const NotForSale: FC<NotForSaleProps> = ({
  image
}) => {
  const {
    not_for_sale: {
      title
    }
  } = messages;
  const eventInfoImage = image && image?.asset?.file?.url;
  return <div className="event-country-availability mw-100 hero__content" data-sentry-component="NotForSale" data-sentry-source-file="NotForSale.tsx">
      {eventInfoImage && <Image fill src={eventInfoImage} alt={image?.alt || ''} className="hero__event-image mx-auto" objectFit="contain" />}
      <OnAirNotification text={title} type={NotificationType.info} className="event-country-availability__items-width mt-4" customIconClassName="oa-exclamation-mark" data-sentry-element="OnAirNotification" data-sentry-source-file="NotForSale.tsx" />
    </div>;
};
export default NotForSale;