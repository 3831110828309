import React, { FC, RefObject } from 'react';
import { useIsVisible } from '../../../hooks/useIsVisible';
import { NormalizedCartListItem } from '../../../types/cart';
import { CartTotal } from './CartTotal';
import { StickyCartAnimation } from './StickyCartAnimation';
type Props = {
  normalizedCartList: NormalizedCartListItem[];
  handleIsLoading: (value: boolean) => void;
  checkoutRef: RefObject<HTMLDivElement>;
  isLoading: boolean;
};
export const StickyCartTotal: FC<Props> = ({
  normalizedCartList,
  handleIsLoading,
  checkoutRef,
  isLoading
}) => {
  const isVisible = useIsVisible(checkoutRef, 1);
  return <StickyCartAnimation upAnimation={!isVisible} data-sentry-element="StickyCartAnimation" data-sentry-component="StickyCartTotal" data-sentry-source-file="StickyCartTotal.tsx">
      <div className="container cart-total-rows-wrapper px-0 pb-2 pb-sm-0 mt-md-0">
        <CartTotal normalizedCartList={normalizedCartList} handleIsLoading={handleIsLoading} isLoading={isLoading} isSticky data-sentry-element="CartTotal" data-sentry-source-file="StickyCartTotal.tsx" />
      </div>
    </StickyCartAnimation>;
};