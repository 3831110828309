import React, { FC } from 'react';
import { ContentfulTestimonialBlock } from '@/types/contentful';
import TestimonialBlockComponent from './TestimonialBlockComponent';
const TestimonialBlock: FC<{
  block: ContentfulTestimonialBlock;
  isFirstBlock: boolean;
}> = ({
  block,
  isFirstBlock
}) => <TestimonialBlockComponent key={block.id} title={block.title} testimonials={block.testimonials} landscape isFirstBlock={isFirstBlock} data-sentry-element="TestimonialBlockComponent" data-sentry-component="TestimonialBlock" data-sentry-source-file="index.tsx" />;
export default TestimonialBlock;